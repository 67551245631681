<template>
  <div class="container mx-auto home">
    <Clients :archived="archived" />
    <br /><br />
    <router-link to="/swot/archived/" class="px-3 py-2 text-white bg-blue-400 rounded text-md"
      ><span class="text-md">&#10094;&#10094;</span>&nbsp;&nbsp; All Archived Clients</router-link
    >
  </div>
</template>

<script>
  // @ is an alias to /src
  import Clients from "@/components/swot/SwotClients.vue";

  export default {
    name: "home",
    components: {
      Clients,
    },
    data() {
      return {
        isOpen: false,
        archived: false,
      };
    },
  };
</script>
